// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-home-js": () => import("./../src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-listen-js": () => import("./../src/pages/listen.js" /* webpackChunkName: "component---src-pages-listen-js" */),
  "component---src-pages-lyrics-js": () => import("./../src/pages/lyrics.js" /* webpackChunkName: "component---src-pages-lyrics-js" */),
  "component---src-pages-releases-js": () => import("./../src/pages/releases.js" /* webpackChunkName: "component---src-pages-releases-js" */),
  "component---src-pages-shows-js": () => import("./../src/pages/shows.js" /* webpackChunkName: "component---src-pages-shows-js" */),
  "component---src-pages-watch-js": () => import("./../src/pages/watch.js" /* webpackChunkName: "component---src-pages-watch-js" */)
}

